import React, { useRef, useEffect } from 'react';
import './ContactUs.css';
import BackgroundVideo from '../Components/L1 - Anm3.mp4';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function ContactUs() {
  const sectionRef = useRef(null);
  const videoRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const videoEl = videoRef.current;
    const contentEl = contentRef.current;
    const sectionEl = sectionRef.current;

    // Reset any existing ScrollTriggers
    ScrollTrigger.getAll().forEach(st => {
      if (st.vars.trigger === sectionEl) {
        st.kill();
      }
    });

    // Set initial states
    gsap.set(videoEl, { filter: 'blur(0px)' });
    gsap.set(contentEl, {
      autoAlpha: 0,
      y: 50,
      scale: 0.95
    });

    // Create a more controlled scroll trigger with delayed start
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionEl,
        start: "top 25%", // Start when the top of the section reaches 25% from the top of viewport
        end: "top -25%", // End when the top of the section is 25% above the viewport
        scrub: 1.5,
        pin: false,
        anticipatePin: 1,
        fastScrollEnd: true,
        preventOverlaps: true,
        onEnter: () => {
          // Only show when properly entering the section
          sectionEl.style.visibility = 'visible';
          // Ensure proper stacking context
          sectionEl.style.zIndex = 1;
        },
        onLeaveBack: () => {
          // Hide when scrolling back up
          sectionEl.style.visibility = 'hidden';
          sectionEl.style.zIndex = 0;
        },
        onUpdate: (self) => {
          // Gradually increase blur based on scroll progress
          const progress = self.progress;
          const blurAmount = progress * 10; // Max blur of 10px
          videoEl.style.filter = `blur(${blurAmount}px)`;
        }
      }
    });

    // Smoother animation sequence with delayed timing
    tl.fromTo(contentEl, {
      autoAlpha: 0,
      y: 50,
      scale: 0.95
    }, {
      autoAlpha: 1,
      y: 0,
      scale: 1,
      duration: 1,
      ease: "power2.out"
    }, 0.3); // Slight delay before content appears

    // Add a marker for the Research section to prevent overlap
    ScrollTrigger.create({
      trigger: ".research1-section",
      endTrigger: ".contact-us-section",
      start: "bottom 75%", // Adjust this value to control when Contact section begins to appear
      end: "bottom top",
      onLeave: () => {
        ScrollTrigger.refresh();
      },
      onLeaveBack: () => {
        ScrollTrigger.refresh();
      }
    });

    return () => {
      // Cleanup
      tl.kill();
      ScrollTrigger.getAll().forEach(st => {
        if (st.vars.trigger === sectionEl) {
          st.kill();
        }
      });
    };
  }, []);

  return (
    <section ref={sectionRef} className="contact-us-section" style={{ visibility: 'hidden' }}>
      <div className="video-background2">
        <video
          ref={videoRef}
          className="background-video2"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={BackgroundVideo} type="video/mp4" />
        </video>
      </div>
      
      <div className="overlay"></div>
      
      <div ref={contentRef} className="contact-content">
        <h2 className="contact-heading">CONTACT US</h2>
        <h3 className="contact-subheading">
          We are always excited to hear from our community!
        </h3>
        
        <div className="paragraphs2-container">
          <p className="paragraph2">
            If you have an idea for a project we could collaborate on, a suggestion for a new
            product you would like to see in our lineup, or if you have discovered a bug that needs
            fixing, please feel free to share it with us. Your ideas and feedback play a crucial
            role in helping us evolve and improve the experience we offer.
          </p>
          
          <p className="paragraph2">
            At the heart of what we do is a commitment to meeting the needs of our users. That is why your input is so important to us. Whether it is a big idea or a small tweak, every
            message is valuable, and we make sure to take it seriously.
          </p>
        </div>
        
        <div className="button-container">
          <Link to="/contact-form" className="contact-button">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
}