import React, { useEffect, useState } from 'react';

const ResourcePreloader = ({ onLoadComplete, resources }) => {
  const [loadedCount, setLoadedCount] = useState(0);

  useEffect(() => {
    const preloadResources = async () => {
      const promises = resources.map(resource => {
        return new Promise((resolve, reject) => {
          if (resource.type === 'video') {
            const video = document.createElement('video');
            video.preload = 'auto';
            video.onloadeddata = () => {
              setLoadedCount(prev => prev + 1);
              resolve();
            };
            video.onerror = reject;
            video.src = resource.src;
          } else if (resource.type === 'image') {
            const img = new Image();
            img.onload = () => {
              setLoadedCount(prev => prev + 1);
              resolve();
            };
            img.onerror = reject;
            img.src = resource.src;
          }
        });
      });

      try {
        await Promise.all(promises);
        onLoadComplete();
      } catch (error) {
        console.error('Error preloading resources:', error);
        onLoadComplete(); // Continue even if some resources fail to load
      }
    };

    preloadResources();
  }, [resources, onLoadComplete]);

  return null; // This component doesn't render anything
};

export default ResourcePreloader;